var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Model } from "vue-property-decorator";
import { State } from "vuex-class";
var PrincipalBatch = /** @class */ (function (_super) {
    __extends(PrincipalBatch, _super);
    function PrincipalBatch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 更新委托方ID
     */
    PrincipalBatch.prototype.emitPrincipalId = function (val) { };
    Object.defineProperty(PrincipalBatch.prototype, "principal", {
        /**
         * 获取委托方绑定到当前组件的委托方value
         */
        get: function () {
            return this.principalId;
        },
        /**
         * 设置当前组件绑定的委托方，并且发送事件到父组件，更新父组件绑定的委托方
         */
        set: function (val) {
            this.emitPrincipalId(val);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        State
    ], PrincipalBatch.prototype, "principalList", void 0);
    __decorate([
        Model("principalIdChange")
    ], PrincipalBatch.prototype, "principalId", void 0);
    __decorate([
        Emit("principalIdChange")
    ], PrincipalBatch.prototype, "emitPrincipalId", null);
    PrincipalBatch = __decorate([
        Component({
            components: {}
        })
    ], PrincipalBatch);
    return PrincipalBatch;
}(Vue));
export default PrincipalBatch;
