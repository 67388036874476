var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import DataBox from "~/components/common/data-box.vue";
import { Emit, Prop } from "vue-property-decorator";
import { CaseApplyManageService } from "~/services/business-service/case-apply-manage.service";
import FileUpload from "~/components/common/file-upload.vue";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import CommonTitle from "~/components/common/common-title.vue";
var ProcessApprove = /** @class */ (function (_super) {
    __extends(ProcessApprove, _super);
    function ProcessApprove() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processFlowNodes = [];
        _this.fileData = [];
        _this.loading = {
            state: false
        };
        _this.submitLoading = false;
        _this.approveModel = {
            approveRemark: "",
            approvalResult: "",
            endCaseDate: "",
            flowType: ""
        };
        _this.rules = {
            approvalResult: [{ required: true, message: "请选择审批结果" }],
            approveRemark: [{ required: true, message: "请输入审批意见" }],
            endCaseDate: [{ required: true, message: "请输入结案日期" }]
        };
        return _this;
    }
    ProcessApprove.prototype.refreshList = function () { };
    ProcessApprove.prototype.close = function () {
        this.reset();
    };
    ProcessApprove.prototype.reset = function () {
        var supplementForm = this.$refs["approve-form"];
        supplementForm.resetFields();
    };
    Object.defineProperty(ProcessApprove.prototype, "stepSort", {
        get: function () {
            return this.processFlowNodes.sort(function (a, b) { return a.nodeValue - b.nodeValue; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProcessApprove.prototype, "approvalHistoryData", {
        get: function () {
            return this.processFlowNodes
                .filter(function (x) { return x.approvalStatus === "APPROVED_COMPLETED"; })
                .sort(function (a, b) { return a.nodeValue - b.nodeValue; });
        },
        enumerable: false,
        configurable: true
    });
    ProcessApprove.prototype.getProcessFlowNodes = function () {
        var _this = this;
        this.caseApplyManageService
            .getProcessFlowNodes(this.rowData.processApplicationId)
            .subscribe(function (data) {
            _this.processFlowNodes = data;
        });
        if (this.rowData.fileId) {
            this.uploadFileService
                .findUploadByFileIds(this.rowData.fileId)
                .subscribe(function (data) {
                _this.fileData = data;
            });
        }
    };
    /**
     * 取消
     */
    ProcessApprove.prototype.cancel = function () {
        this.$emit("close");
        var supplementForm = this.$refs["approve-form"];
        supplementForm.resetFields();
    };
    ProcessApprove.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.id)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    /**
     * 提交审批
     */
    ProcessApprove.prototype.confirmApproved = function () {
        var _this = this;
        var Form = this.$refs["approve-form"];
        Form.validate(function (valid) {
            if (!valid)
                return;
            var approvedModel = {
                processFlowNodeId: _this.rowData.processFlowNodeId,
                processApplicationId: _this.rowData.processApplicationId,
                caseId: _this.rowData.caseId,
                approvalResult: _this.approveModel.approvalResult == 0
                    ? "APPROVED_PASS"
                    : "APPROVED_REJECT",
                flowType: _this.rowData.flowType,
                approveRemark: _this.approveModel.approveRemark,
                endCaseDate: _this.approveModel.endCaseDate
            };
            _this.submitLoading = true;
            _this.loading.state = true;
            _this.caseApplyManageService
                .caseApplyApprove(approvedModel, _this.loading)
                .subscribe(function (data) {
                _this.loading.state = false;
                _this.$message.success("审批成功!");
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], ProcessApprove.prototype, "caseApplyManageService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], ProcessApprove.prototype, "uploadFileService", void 0);
    __decorate([
        Emit("refreshList")
    ], ProcessApprove.prototype, "refreshList", null);
    __decorate([
        Emit("close")
    ], ProcessApprove.prototype, "close", null);
    __decorate([
        Prop()
    ], ProcessApprove.prototype, "rowData", void 0);
    ProcessApprove = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DataBox: DataBox,
                CommonTitle: CommonTitle
            }
        })
    ], ProcessApprove);
    return ProcessApprove;
}(Vue));
export default ProcessApprove;
