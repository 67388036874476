var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import FileUpload from '~/components/common/file-upload.vue';
var BatchApprove = /** @class */ (function (_super) {
    __extends(BatchApprove, _super);
    function BatchApprove() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.visitModel = {
            approveRemark: '',
            approveResult: ''
        };
        _this.loading = {
            state: false
        };
        _this.submitLoading = false;
        _this.rules = {
            approveRemark: [{ required: true, message: '请输入审批意见' }],
            approveResult: [{ required: true, message: '请选择审批结果' }],
        };
        return _this;
    }
    BatchApprove.prototype.refreshList = function () { };
    BatchApprove.prototype.close = function () {
        this.reset();
    };
    /**
     * 重置
     */
    BatchApprove.prototype.reset = function () {
        var visitForm = this.$refs['batch-approved-form'];
        visitForm.resetFields();
    };
    BatchApprove.prototype.confirmApproved = function () {
        var _this = this;
        var visitForm = this.$refs['batch-approved-form'];
        visitForm.validate(function (valid) {
            if (!valid)
                return;
            var approveModel = [];
            if (_this.selectionCases) {
                approveModel = _this.selectionCases.map(function (approve) {
                    return {
                        processFlowNodeId: approve.processFlowNodeId,
                        processApplicationId: approve.processApplicationId,
                        caseId: approve.caseId,
                        approvalResult: _this.visitModel.approveResult == 0 ? 'APPROVED_PASS' : 'APPROVED_REJECT',
                        flowType: approve.flowType,
                        approveRemark: _this.visitModel.approveRemark
                    };
                });
            }
            _this.submitLoading = true;
            _this.loading.state = true;
            _this.caseApplyManageService.approveByBatch(approveModel, _this.loading).subscribe(function (data) {
                _this.$message.success('审批成功!');
                _this.submitLoading = false;
                _this.refreshList();
                _this.close();
            }, function (_a) {
                var msg = _a.msg;
                _this.submitLoading = false;
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyManageService)
    ], BatchApprove.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit('refreshList')
    ], BatchApprove.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], BatchApprove.prototype, "close", null);
    __decorate([
        Prop({})
    ], BatchApprove.prototype, "selectionCases", void 0);
    BatchApprove = __decorate([
        Component({
            components: {
                FileUpload: FileUpload,
            },
        })
    ], BatchApprove);
    return BatchApprove;
}(Vue));
export default BatchApprove;
